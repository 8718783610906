import qs from 'qs'
import paths from 'constants/paths'
import environment from 'libs/environment'

const BASE_URL = environment.isProduction
  ? 'https://myc.ochsner.org/FHIR'
  : environment.isStaging
    ? 'https://epicproxyn.ochsner.org/FHIR'
    : 'https://appmarket.epic.com/interconnect-amcurprd-oauth'
const CLIENT_ID = environment.isProduction
  ? '92a41cbd-e159-4e07-a23a-387f1d0ce227'
  : environment.isStaging
    ? 'a0216c60-1fab-4da8-a6ad-802c56470f93'
    : '3e95c176-92ad-4910-8d97-7e871f656145'
const REDIRECT_URI = `${environment.DOMAIN}${paths.ENTERPRISE_OCTOPUS}`

const exchangeAuthCodeForToken = async (code: string) =>
  fetch(`${BASE_URL}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    body: qs.stringify({
      client_id: CLIENT_ID,
      code,
      grant_type: 'authorization_code',
      redirect_uri: REDIRECT_URI,
    }),
  }).then((response) => response.json())

const epicSignIn = (launch = null) => {
  const queryObject = {
    aud: `${BASE_URL}/oauth2/token`,
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    response_type: 'code',
  }

  if (launch) {
    queryObject.launch = launch
    queryObject.scope = 'launch'
  }

  window.location.href = `${BASE_URL}/oauth2/authorize?${qs.stringify(queryObject)}`
}

const signInFromMyChart = (launch: unknown) => {
  epicSignIn(launch)
}

type PatientReadData = unknown

const getDob = (patientReadData: PatientReadData) => patientReadData.birthDate

const getEmail = (patientReadData: PatientReadData) => {
  const { telecom } = patientReadData
  if (!telecom) {
    return null
  }
  return telecom.find((item) => item.system === 'email')?.value
}

const getGender = (patientReadData: PatientReadData) => patientReadData.gender

const getId = (patientReadData: PatientReadData) => {
  const { identifier } = patientReadData
  if (!identifier) {
    return null
  }
  return identifier.find((item) => item.type?.text === 'EXTERNAL')?.value.trim()
}

const getName = (patientReadData: PatientReadData) => {
  const { name } = patientReadData
  if (!name) {
    return {}
  }
  const fullName = name.find((item) => item.use === 'usual')
  return {
    firstName: fullName.given[0],
    lastName: fullName.family,
  }
}

// mobile phone first, then home phone, then work
const getPhone = (patientReadData: PatientReadData) => {
  const { telecom } = patientReadData
  if (!telecom) {
    return null
  }
  const phoneOptions = {}
  telecom.forEach((item) => {
    if (item.system === 'phone') {
      phoneOptions[item.use] = item.value
    }
  })
  if (phoneOptions.mobile) {
    return phoneOptions.mobile
  }
  if (phoneOptions.home) {
    return phoneOptions.home
  }
  if (phoneOptions.work) {
    return phoneOptions.work
  }
  return null
}

const fetchEpicPatientData = async (accessToken: string, epicPatientId: string) => {
  const url = `${BASE_URL}/api/FHIR/STU3/Patient/${epicPatientId}?_format=json`
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  }
  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then((response) => response.json())
    .then((data) => {
      if (!data) return null
      return {
        ...getName(data),
        dateOfBirth: getDob(data),
        email: getEmail(data),
        gender: getGender(data),
        id: getId(data),
        phoneNumber: getPhone(data),
      }
    })
}

const identifyEpicUserFromCode = async (code: string) => {
  const accessData = await exchangeAuthCodeForToken(code)
  // eslint-disable-next-line camelcase
  const { access_token, patient } = accessData
  const patientData = await fetchEpicPatientData(access_token, patient)
  return patientData
}

export default {
  epicSignIn,
  identifyEpicUserFromCode,
  signInFromMyChart,
}
